import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    nationalities: [],
    meta: { currentPage: 0,
        nextPage: false,
        prevPage: false,
        totalPages: 0,
        totalRecords: 0 },
};
// ----------------------------------------------------------------------
export const getNationalities = createAsyncThunk('agency/getNationalities', async ({ page, rowsPerPage, orderBy = 'createdAt', order = 'desc', filterName = '', isPaging = false, }) => {
    let data;
    const queryParams = {
        search: {
            code: filterName,
        },
        page: Number(page) + 1,
        perPage: rowsPerPage,
        orderBy,
        sortedBy: order,
        isPaging,
    };
    try {
        const url = createDynamicURL('/nationalities', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
// ----------------------------------------------------------------------
const slice = createSlice({
    name: 'nationality',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNationalities.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getNationalities.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.nationalities = action.payload.data.docs;
            state.meta = action.payload.meta;
        })
            .addCase(getNationalities.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice.reducer;
